import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {isDesktop, isTablet} from "react-device-detect";
import {AnchorHTMLAttributes, FormEvent, useState} from "react";
import {IoRefreshOutline} from "react-icons/io5";
import FrameButton from "@/components/buttons/FrameButton";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import classNames from "classnames";
import useCreateRefreshNftOwnerships from "@/hooks/useCreateRefreshNftOwnerships";
import SignUpToast from "@/components/SignUpToast";
import {ReactComponent as SearchIcon} from "../../svgs/search.svg";
import GuildCard from "@/pages/guilds/GuildCard";
import {useNavigate} from "react-router-dom";
import DragScroll from "@/components/DragScroll";


export default function GuildsHeader() {
    const history = useNavigate();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: guildOwnerships, isLoading: isGuildOwnershipsLoading} = useGetGuildOwnerships(fullToken, true);
    const isDefaultState = !fullToken || guildOwnerships?.badges.length === 0;
    const {isRefreshing, refreshNftOwnerships} = useCreateRefreshNftOwnerships();

    const [showLogInToast, setShowLogInToast] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>("");

    function getBackgroundUrl(): string {
        const size: string = isDesktop || isTablet ? "LG" : "SM";
        return `guild-banner-${isDefaultState ? "default" : "pending"}-${size}.webp`;
    }

    function handleToastDismissal(): void {
        setShowLogInToast(false);
    }

    function handleSubmitSearch(e: FormEvent) {
        e.preventDefault();
        history(`/guilds?search=${searchValue}`);
    }

    return (
        <div
            className={classNames("guilds-header", {
                "guilds-header_pending": !isDefaultState,
                "guilds-header_default": isDefaultState,
                "react-loading-skeleton": fullToken && isGuildOwnershipsLoading,
            })}
        >
            <SignUpToast
                message={"Join DevourGO to unlock your GoPlay journey!"}
                isOpen={showLogInToast}
                onDismiss={handleToastDismissal}
            />
            {(!fullToken || guildOwnerships) &&
                <>
                    <img src={`${import.meta.env.VITE_CDN_URL}/images/${getBackgroundUrl()}`} alt=""
                        className="guilds-header_bg"/>
                    <div className="guilds-header_title">
                        <h1>JOIN YOUR <span>GUILD</span></h1>
                        <strong className="guilds-header_title_subtitle">
                            Join a guild to get benefits, rewards and access to exclusive menu items!
                        </strong>
                    </div>

                    {/* Default state */}
                    {isDefaultState && <div className="guilds-header_default_buttons">
                        <FrameButton<AnchorHTMLAttributes<HTMLAnchorElement>>
                            href={"https://www.overwolf.com/app/Devour-DevourPlay"}
                            forwardProps={{target: "_blank", rel: "noopener noreferrer"}}
                            color="white-drop-shadow-devour"
                            size="normal"
                            className="gofrens-header_content_container_left_button-container_refresh"
                        >
                            Download DevourPlay
                        </FrameButton>

                        <button
                            onClick={async () => fullToken ? await refreshNftOwnerships(true) : setShowLogInToast(true)}
                            disabled={!fullToken || isGuildOwnershipsLoading || isRefreshing}
                            className="reset-button guilds-header_default_buttons_refresh"
                        >
                            {isRefreshing ? "Refreshing..." : "Refresh My Badges"}
                            <IoRefreshOutline size="18px"/>
                        </button>
                    </div>}

                    {/* Pending state */}
                    {!isDefaultState && <div className="guilds-header_pending-content">
                        <form onSubmit={handleSubmitSearch} className="gofrens-header-no-rep_container_header_search">
                            <input
                                type="text"
                                placeholder="Search a guild or badge"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <button className="gofrens-header-no-rep_container_header_search_button">
                                <span className="gofrens-header-no-rep_container_header_search_button_text">Let's go</span>
                                <SearchIcon/>
                            </button>
                        </form>

                        <div className="gofrens-header-no-rep_container_communities guilds-header_pending-content_guilds">
                            <h3>Join a Guild Now!</h3>
                            <div className="gofrens-header-no-rep_container_communities_body">
                                <DragScroll className="gofrens-header-no-rep_container_communities_body_cards guilds-header_pending-content_guilds_container">
                                    <>
                                        {guildOwnerships.qualifiedGuilds.map(guild =>
                                            <GuildCard key={guild.id} guild={guild} className="gofrens-rep-community-card"/>)}
                                    </>
                                </DragScroll>
                            </div>
                        </div>
                    </div>}
                </>
            }
        </div>
    );
}