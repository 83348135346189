import {Guild, GuildQuest} from "@devour/client";
import React, {useMemo, useState} from "react";
import classNames from "classnames";
import CircularProgressIndicator from "@/components/CircularProgress";
import {TbTicket} from "react-icons/tb";
import AnimateHeight from "react-animate-height";
import {BsTriangleFill} from "react-icons/bs";
import {IoMdCheckmark, IoMdLock} from "react-icons/io";
import {useAsset} from "@/hooks/useAsset";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {isMobile, isTablet} from "react-device-detect";
import GuildMintBadgeButton from "@/components/guilds/GuildMintBadgeButton";

interface Props {
    quests: Array<GuildQuest>;
    guild: Guild;
    forceExpanded?: boolean;
    isOnModal?: boolean;
    locked?: boolean;
}

export default function GuildQualifierCard(props: Props) {
    const {guild, quests} = props;
    const [isCollapsed, setIsCollapsed] = useState<boolean>(props.forceExpanded === undefined);
    const {data: asset} = useAsset(guild.hero);
    const guildQualifierQuests = useMemo(() => (quests || []).filter(quest => quest.isGuildQualifier), [quests]);
    const numOfQuestsCompleted = guildQualifierQuests.filter((quest) => quest.userStat?.isCompleted).length;
    const isOnOverwolf = useSelector((store: IStore) => store.metaStore.isOnOverwolf);

    if (!guildQualifierQuests.length) {
        return null;
    }

    function expandQuests() {
        if (props.forceExpanded) {
            return;
        }

        setIsCollapsed(!isCollapsed);
    }

    return (
        <>
            <div
                className={classNames(
                    "go-vip-ow_quest-reward-card",
                    "guild-qualifier-card", {
                        "small-size": !isOnOverwolf || isMobile,
                    },
                )}
            >
                <div
                    className={classNames("guild-qualifier-card_background", {
                        "no-progress": numOfQuestsCompleted === 0,
                    })}
                >
                    {asset?.url && <img src={asset.url} alt={guild.name}/>}
                </div>
                <div className="go-vip-ow_quest-reward-card_main">
                    <div className="go-vip-ow_quest-reward-card_info">
                        <CircularProgressIndicator
                            showGlow={numOfQuestsCompleted === guildQualifierQuests.length}
                            total={guildQualifierQuests.length}
                            current={numOfQuestsCompleted}
                        />

                        <div className="go-vip-ow_quest-reward-card_info_content">
                            <strong className="go-vip-ow_quest-reward-card_info_content_title">
                                {guild.name} Qualifier
                            </strong>
                            <p className="go-vip-ow_quest-reward-card_info_content_description">
                                Complete this quest to earn the guild pass and join the {guild.name}.
                            </p>

                            <strong
                                className={classNames("go-vip-ow_quest-reward-card_info_content_points guild-qualifier-card_pass", {
                                    "no-progress": numOfQuestsCompleted === 0,
                                })}>
                                <TbTicket className="guild-qualifier-card_pass-icon"/>
                                {guild.name} Pass
                            </strong>
                        </div>

                        {!props.forceExpanded && <BsTriangleFill
                            className={classNames(
                                "go-vip-ow_quest-reward-card_info_icon",
                                "go-vip-ow_quest_milestones_collapse-icon", {
                                    "go-vip-ow_quest_milestones_collapse-icon_flipped": !isCollapsed,
                                },
                            )}
                            onClick={expandQuests}
                        />}

                        {props.locked && <IoMdLock
                            className="go-vip-ow_quest-reward-card_info_icon go-vip-ow_quest-reward-card_info_icon_lock"
                            size="24px"
                        />}

                    </div>
                </div>

                {guildQualifierQuests.length > 0 &&
                    <AnimateHeight
                        height={isCollapsed ? 0 : "auto"}
                        duration={300}
                    >
                        <div className="guild-qualifier-card_content">

                            <div
                                className={classNames(
                                    "guild-qualifier-card_content_container",
                                    {
                                        "small-size": props.isOnModal || (isMobile && !isTablet),
                                    },
                                )}
                            >
                                <div className="guild-qualifier-card_content_quests">
                                    {guildQualifierQuests.map((quest, index) =>
                                        <React.Fragment key={quest.id}>
                                            <div className="guild-qualifier-card_content_quests_quest">
                                                <div
                                                    className={classNames("guild-qualifier-card_content_quests_quest_checkmark", {
                                                        "completed": quest.userStat?.isCompleted,
                                                    })}>
                                                    <IoMdCheckmark size="1rem"/>
                                                </div>
                                                {quest.description}
                                            </div>
                                            {index !== guildQualifierQuests.length - 1 &&
                                                <div className="guild-qualifier-card_content_quests_divider"/>}
                                        </React.Fragment>)}
                                </div>

                                <div className="guild-qualifier-card_content_claim">
                                    {/* TODO: PLACE HOLDER IMAGE. REPLACE WITH ACTUAL BADGE IMAGES WHEN AVAILABLE*/}
                                    <img
                                        alt={"Guild Proof of Play Badge"}
                                        src={`${import.meta.env.VITE_CDN_URL}/images/placeholder_badge.png`}
                                    />
                                    <GuildMintBadgeButton
                                        guild={guild}
                                        isOnModal={props.isOnModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </AnimateHeight>
                }
            </div>
        </>
    );
}