import {TopGuildLeaderLeaderboard} from "@devour/client";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import useThemePreference from "@/hooks/useThemePreference";
import GoFrensLeaderboardRow from "@/components/goFrens/GoFrensLeaderboardRow";
import GuildMembersLeaderboardEmpty from "@/pages/guilds/GuildMembersLeaderboardEmpty";
import GoFrensTopLeaderboardCommunityTableHeader
    from "@/components/goFrens/topLeaderboard/GoFrensTopLeaderboardCommunityTableHeader";

interface Props {
    members: Array<TopGuildLeaderLeaderboard>;
    isModal?: boolean;
    isAroundUserRanking?: boolean;
    isCurrentAllTime?: boolean;
}
export default function GuildMembersLeaderboard(props: Props) {
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const {isOnDarkMode} = useThemePreference();
    const myRankIcon = <span className="my-rank-icon">Me</span>;
    const rankedUser = props.members?.find(member =>
        member.id === currentUser?.user?.id && member.totalPoints > 0);

    if (props.isAroundUserRanking && !rankedUser) {
        return <div className="my-community-leaderboard_user-unranked">
            <p>Your rank leaderboard will be displayed here along with two other competitors, once you have more
                than 1 XP.</p>
            <GoFrensLeaderboardRow
                profile={{
                    name: currentUser?.user?.nickname || "GoVIP Player",
                    photo: currentUser?.user?.gravatar,
                }}
                accumulatedPoints="0 XP"
                className="gofrens-leaderboard-row_user-unranked"
                bgColor={isOnDarkMode ? "DM-light-gray" : "light-gray"}
            />
        </div>;
    }

    if (!props.members.length) {
        return <GuildMembersLeaderboardEmpty
            isCurrentAllTime={props.isCurrentAllTime}
            isModal={props.isModal}
        />;
    }

    function renderBoardRow(member: TopGuildLeaderLeaderboard, index) {
        const bgColor = props.isAroundUserRanking
            ? "white"
            : (index % 2 === 0 ? "light-gray" : "white");

        return <GoFrensLeaderboardRow
            key={member.id}
            rank={member.rank}
            accumulatedPoints={member.totalPoints}
            profile={{
                name: member.nickname || "GoVIP Player",
                photo: member.profilePicture,
            }}
            rightIconToName={props.isAroundUserRanking && currentUser?.user?.id === member.id && myRankIcon}
            bgColor={`${isOnDarkMode ? "DM-" : ""}${bgColor}`}
            className={currentUser?.user?.id === member.id && "gofrens-leaderboard-row_my-rank"}
        />;
    }

    return (
        <div className="my-community-leaderboard guilds-top-leaderboard_boards">
            <GoFrensTopLeaderboardCommunityTableHeader/>
            <div>
                {props.members.map(renderBoardRow)}
            </div>
        </div>

    );
}