import { IoIosCheckmarkCircle } from "react-icons/io";
import FrameOneModal from "./modalComponents/FrameOneModal";
import FrameButton from "../buttons/FrameButton";
import { IoClose } from "react-icons/io5";
import { restaurantSearchRedirect } from "@/utils/restaurantSearchRedirect";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import { useNavigate } from "react-router-dom";


interface Props {
    isOpen: boolean;
    image: string;
    dateMinted: Date;
    description: string;
    onClose: () => void;
}

// box-shadow: 0px 0px 8px -4px rgba(127, 86, 217, 1);

// box-shadow: 0px 0px 24px -4px rgba(128, 79, 211, 1);


export const NftPromoModal = (props: Props) => {

    const dateMintedString = props.dateMinted.toLocaleDateString("en-US", { year: "numeric",
        month: "short",
        day: "numeric" });

    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const lastSearchedPlaceId = useSelector((store: IStore) => store.metaStore.lastSearchedPlaceId);

    const navigate = useNavigate();

    const onClickUsePromo = async () => {
        const redirectRoute = await restaurantSearchRedirect(currentUser?.user?.addresses, lastSearchedPlaceId);
        if (redirectRoute) {
            navigate(redirectRoute);
        } else {
            navigate("/restaurants");
        }
    };


    return (
        <FrameOneModal isOpen={props.isOpen} toggle={props.onClose}>
            <div className="nft-promo-modal_container">
                <div className="nft-promo-modal_x-button" onClick={props.onClose}><IoClose size={24}/></div>
                <img src={props.image} alt={"Promo Minted"} style={{marginBottom: "1.2rem"}}/>
                <div>
                    <h4 className="nft-promo-modal_cta">Promotion Claimed <span style={{marginLeft: "0.48rem"}}><IoIosCheckmarkCircle size={20}/></span></h4>
                    <p style={{color: "rgba(135, 135, 141, 1)",
                        fontSize: "0.72rem",
                        marginBottom: "0.6rem",
                        marginBlock: "0px 0px"}}>Claimed on {dateMintedString}</p>
                </div>
                <p className="nft-modal-promo_description">{props.description}</p>
                <FrameButton color="purple" className="nft-promo-modal_use-promo-button" onClick={onClickUsePromo}><span className="nft-promo-modal_use-promo-button_text">Use Promo Now</span></FrameButton>
                <FrameButton className="nft-promo-modal_close-secondary-button" color="purple-text-no-border" onClick={props.onClose}><span className="nft-promo-modal_close-secondary-button_text">Close</span></FrameButton>
            </div>
        </FrameOneModal>
    );
};