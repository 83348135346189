import {GetOverwolfXpSubjectEnum, Guild} from "@devour/client";
import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import {useSelector} from "react-redux";
import DriftDrawer from "@/components/DriftDrawer";
import CommunityDrawerHeaderGraphic from "@/components/goFrens/communityInfoDrawer/CommunityDrawerHeaderGraphic";
import CommunityGuildInfo from "@/components/goFrens/communityInfoDrawer/CommunityGuildInfo";
import {useAsset} from "@/hooks/useAsset";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import useGetUserOverwolfXP from "@/hooks/useGetUserOverwolfXP";
import {IStore} from "@/redux/defaultStore";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import GuildQualifierCard from "@/pages/guilds/GuildQualifierCard";
import {FaArrowRight, FaWindows} from "react-icons/fa";
import FrameButton from "@/components/buttons/FrameButton";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import classNames from "classnames";
import JoinGuildModal from "@/pages/guilds/JoinGuildModal";
import { DateTime } from "luxon";
import {ReactComponent as Timer} from "@/svgs/timer.svg";


interface Props {
    isOpen: boolean;
    toggle: () => void;
    guild: Guild;
}

export default function GuildInfoDrawer(props: Props): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: hero} = useAsset(props.guild.hero);
    const {data: icon} = useAsset(props.guild.icon);
    const {data: rewardsData} = useGetUserOverwolfXP(fullToken, GetOverwolfXpSubjectEnum.TIMEREWARD, undefined);
    const {data: guildOwnerships} = useGetGuildOwnerships(fullToken, true);
    const { data: guildProgress} = useGetGuildProgress(props.guild.id, fullToken, undefined, props.isOpen);

    const [showJoinGuildModal, setShowJoinGuildModal] = useState<boolean>(false);

    function getButtonContent() {
        if (!(rewardsData?.transactions.length > 0)) {
            return {
                disabled: false,
                text: "Download DevourPlay",
                rightIcon: FaWindows,
                leftIcon: null,
                onClick: () => window.open("https://www.overwolf.com/app/Devour-DevourPlay", "_blank"),
            };
        }

        if (!guildOwnerships) {
            return;
        }

        let disabled = !guildProgress?.doesUserHaveBadge;
        let text: string = `Join ${props.guild.name} Guild`;
        let leftIcon: React.FC;
        let rightIcon: React.FC = FaArrowRight;
        if (!disabled && !guildOwnerships.membership && guildOwnerships.lastMembership && guildOwnerships.lastMembership.guild !== props.guild.id) {
            // if user is not a member of the guild and this is not the last guild they joined, make sure they can only join in first 7 days of month
            const now = DateTime.now();
            const startOfMonth = now.startOf("month");
            const nextStartOfMonth = startOfMonth.plus({month: 1});

            if (guildOwnerships.lastMembership.leftAt >= startOfMonth.toMillis() && now >= startOfMonth.plus({ days: 7 })) {
                const cooldownTime = Math.ceil(nextStartOfMonth.diff(now, "days").days);
                text += ` (${cooldownTime} day${cooldownTime > 1 ? "s" : ""} left)`;
                disabled = true;
                leftIcon = Timer;
                rightIcon = null;
            }
        }
        return {
            disabled,
            text,
            rightIcon,
            leftIcon,
            onClick: () => disabled ? null : toggleJoinGuildModal(),
        };
    }

    const buttonContent = getButtonContent();

    function renderQuestCardSkeleton(): ReactElement {
        return (
            <div
                style={{height: "340px"}}
                className="guild-qualifier-card react-loading-skeleton"></div>
        );
    }

    function toggleJoinGuildModal() {
        setShowJoinGuildModal(!showJoinGuildModal);
    }

    return (
        <>
            {props.guild && <JoinGuildModal
                isOpen={showJoinGuildModal}
                toggle={toggleJoinGuildModal}
                guild={props.guild}
            />}
            <DriftDrawer
                isOpen={props.isOpen}
                toggle={props.toggle}
                className={classNames("guild-info-drawer", {
                    "is-mobile": isMobile && !isTablet,
                })}
                width={(isDesktop || isTablet) && "576px"}
            >
                <div className="gofrens-community-info-drawer_contents guild-info-drawer_contents">
                    <CommunityDrawerHeaderGraphic
                        toggle={props.toggle}
                        hero={hero?.url}
                        icon={icon?.url}
                        name={props.guild.name}
                    />
                    <div className="guild-info-drawer_main">
                        <CommunityGuildInfo
                            memberCount={props.guild?.memberCount}
                            rank={props.guild?.monthlyRank}
                            guild={props.guild}
                            description={props.guild?.description}
                        />

                        <div className="guild-info-drawer_main_badges">
                            <div className="guild-info-drawer_main_badges_top">
                                <h4>Owned Badges</h4>
                                <div className="gofrens-my-nfts_header_main_top_number">
                                    <span>{guildOwnerships?.badges.filter(badge => badge.nftGroupingId === props.guild.nftGrouping).length}</span>
                                </div>
                            </div>

                            {guildProgress
                                ? <GuildQualifierCard
                                    quests={guildProgress.quests}
                                    guild={guildProgress.guild}
                                    forceExpanded={true}
                                    locked={!(rewardsData?.transactions.length > 0)}
                                />
                                : renderQuestCardSkeleton()
                            }
                        </div>
                    </div>

                </div>
                <div className="gofrens-community-info-drawer_footer">
                    {guildOwnerships?.membership?.guild !== props.guild?.id && buttonContent && <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        color="purple"
                        size="large"
                        rightIcon={buttonContent.rightIcon}
                        leftIcon={buttonContent.leftIcon}
                        forwardProps={{disabled: buttonContent.disabled}}
                        showSpinner={true}
                        className="rep-button"
                        onClick={buttonContent.onClick}
                    >
                        {buttonContent.text}
                    </FrameButton>}
                    <button
                        onClick={props.toggle}
                        className="close-btn"
                    >
                        Close
                    </button>
                </div>
            </DriftDrawer>
        </>
    );
}