import {ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {IStore} from "../../../redux/defaultStore";
import {decrementModalCount, incrementModalCount} from "../../../redux/meta/metaActions";
import variables from "../../../style/variables/scssVariables";
import useWindowSize from "../../../hooks/useWindowSize";
import {isTablet} from "react-device-detect";

// Get the modal timing variable from the css exports
const modalTiming: number = parseInt(variables["frame-one-bottom-panel-transition-timing"]);
const initialModalzIndex: number = 50;

interface Props {
    isOpen: boolean;
    children: ReactNode;
    toggle?: () => void;
    contentClassName?: string;
    containerClassName?: string;
    maxHeight?: boolean;
    floatingHeader?: ReactNode;
    disableOverlayDismiss?: boolean;
    disableFullHeight?: boolean;
    fullScreenHeight?: boolean;
}

function FrameOneBottomPanel(props: Props): ReactElement {
    const activeModals = useSelector((store: IStore) => store.metaStore.activeModals);
    const dispatch = useDispatch();
    const {isOpen} = props;
    const [
        _windowWidth,
        windowHeight,
    ] = useWindowSize();
    const lockRef = useRef<boolean>(false);
    const [
        zIndex,
        setZIndex,
    ] = useState<number>(initialModalzIndex); // Interim solution for nested modals to handle their render order without requiring config for each implementation.
    const [
        intermediateController,
        setIntermediateController,
    ] = useState(undefined); // used as an intermediary to help control the animations & visual state of the modal.
    const [
        shader,
        setShader,
    ] = useState<boolean>(undefined); // helps control the background color of the overlay.
    const [
        forceCloser,
        setForceCloser,
    ] = useState<boolean>(true); // used on a delay to let the animation play out when the modal closes before the "display" is set back to "none" to prevent cutting the animation short.
    const [
        closing,
        setClosing,
    ] = useState<boolean>(false);

    useEffect(() => {
        if (isOpen && !lockRef.current) {
            lockRef.current = true; // lock modal in open state
            setIntermediateController(isOpen);
            setForceCloser(false);
            dispatch(incrementModalCount());
        } else if (isOpen && lockRef.current) { // if modal is open and locked, set the zIndex
            setZIndex(initialModalzIndex + activeModals);
        } else if (isOpen === false && lockRef.current) { // unlock modal when closing
            lockRef.current = false;
            dispatch(decrementModalCount());
            setShader(false);
            // wait a short delay before resetting closing let the animation play out
            setTimeout(() => {
                setClosing(false);
            }, 250);
        }
    }, [isOpen, lockRef.current]);

    // Ensure the modal count is decremented when the component is unmounted (either while the modal is open or locked in the open state).
    useEffect(() => {
        return () => {
            if (lockRef.current) {
                lockRef.current = false;
                dispatch(decrementModalCount());
                setShader(false);
            }
        };
    }, []);

    useEffect(() => {
        if (isOpen && !lock) {
            setZIndex(initialModalzIndex + activeModals);
        }
    }, [activeModals]);

    useEffect(() => {
        function turnOnShader(): void {
            setShader(true);
        }

        function turnOnForceCloser(): void {
            setForceCloser(true);
        }

        if (intermediateController) {
            setTimeout(() => {
                turnOnShader();
            }, 100);
        } else if (intermediateController === false) {
            setTimeout(() => {
                turnOnForceCloser();
            }, modalTiming);
        }
    }, [intermediateController]);

    useEffect(() => {
        function turnOffIntermediate(): void {
            setIntermediateController(false);
        }

        if (shader === false) {
            setTimeout(() => {
                turnOffIntermediate();
            }, 100);
        }
    }, [shader]);

    useEffect(() => {
        // Disables all scrolling until there's no active modals.
        // if you're not using some kind of toggle to close your modal
        // which handles the modal count, you need to manually decrement
        // via props.dispatch.
        if (activeModals > 0) {
            document.body.style.overflow = "hidden";

            if (isTablet) {
                document.getElementById("drift-widget-wrapper")
                    ?.classList.add("drift-frame-controller-hide");
            }
        } else {
            document.body.style.overflow = "auto";
            if (isTablet) {
                document.getElementById("drift-widget-wrapper")
                    ?.classList.remove("drift-frame-controller-hide");
            }
        }
    }, [activeModals]);

    function toggleHelper(): void {
        if (props.toggle && !isOpen) {
            props.toggle();
        } else {
            setClosing(true);
            // Animate the closing of the modal before actually toggling off
            if (props.toggle) {
                setTimeout(() => {
                    props.toggle();
                }, 250);
            }
        }
    }

    function getHeight(): string {
        if (props?.fullScreenHeight) {
            return "100vh";
        }
        if (props.maxHeight && !props.disableFullHeight) {
            return `${windowHeight * 0.9}px`;
        }
        return "auto";
    }

    if (forceCloser) {
        return null;
    }

    return (
        <div
            style={{
                zIndex,
                height: props.disableFullHeight
                    ? "auto"
                    : windowHeight,
            }}
            className={classNames("frame-one-bottom-panel-container", {
                "frame-one-bottom-panel-container_closed": !intermediateController && forceCloser,
                "frame-one-bottom-panel-container_closing": closing,
                "frame-one-bottom-panel-container_open": intermediateController,
                "frame-one-bottom-panel-container_shader": shader,
                [props.containerClassName]: props.containerClassName,
            })}
        >
            <div className="frame-one-bottom-panel-container_inner">
                {props.floatingHeader && // if props.floatingHeader exists, add it on top here.
                <div
					    style={{
					        zIndex: zIndex - 1,
				    }}
					    className={classNames(
					        "frame-one-bottom-panel-container_floating_header",
					        "frame-one-bottom-panel",
					        props.contentClassName,
					        {
					            "frame-one-bottom-panel-container_inner_content_closed": !shader,
					            "frame-one-bottom-panel-container_inner_content_open": shader,
					        },
					    )}
                >
					    {props.floatingHeader}
                </div>}
                <div
                    className={classNames("frame-one-bottom-panel", "frame-one-bottom-panel-container_inner_content", props.contentClassName, {
                        "frame-one-bottom-panel-container_inner_content_closed": !shader,
                        "frame-one-bottom-panel-container_inner_content_open": shader,
                        "frame-one-bottom-panel-container_inner_content_closing": closing,
                        "frame-one-bottom-panel-container_inner_content_fullscreen-height": props?.fullScreenHeight,
                        "frame-one-bottom-panel-container_inner_content_non-fullscreen": !props?.fullScreenHeight,
                    })}
                    style={{
                        height: getHeight(),
                    }}
                >
                    {props.children}
                </div>

                <div
                    onClick={props.disableOverlayDismiss
                        ? null
                        : toggleHelper}
                    className="frame-one-bottom-panel-container_inner_clicker"
                />
            </div>
        </div>
    );
}

export default FrameOneBottomPanel;
