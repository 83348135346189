/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NftType {
    INDUSTRYPASS = 'INDUSTRY_PASS',
    BRANDMAPAUTOMINT = 'BRAND_MAP_AUTOMINT',
    GUILDBADGEAUTOMINT = 'GUILD_BADGE_AUTOMINT',
    OVERWOLFQUESTREWARD = 'OVERWOLF_QUEST_REWARD',
    GUILDQUESTREWARD = 'GUILD_QUEST_REWARD'
}

export function NftTypeFromJSON(json: any): NftType {
    return NftTypeFromJSONTyped(json, false);
}

export function NftTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NftType {
    return json as NftType;
}

export function NftTypeToJSON(value?: NftType | null): any {
    return value as any;
}

